import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

import { useAuthData } from '@/hooks/useAuthData';
import { useRouter } from '@/hooks/useRouter';
import { usePathname } from '@/i18n/navigation';
import { StringBool } from '@/models/StringBool.enum';
import {
  getAllParamsFromSearchParams,
  stringifyUrl,
} from '@/utils/stringify-url';

import { UserModals } from '../../UserModal';
import { HeaderQueryActions } from '../shared/header-query-actions.enum';

type UseUserModalProps = {
  isMobile?: boolean;
};

export default function useUserModal({
  isMobile = false,
}: UseUserModalProps = {}) {
  const pathname = usePathname();
  const router = useRouter();
  const [modalType, setModalType] = useState<UserModals>();
  const [email, setEmail] = useState('');
  const searchParams = useSearchParams();
  const { isAuthenticated } = useAuthData();

  useEffect(() => {
    if (
      !searchParams ||
      searchParams.get('action') !== HeaderQueryActions.LOGIN ||
      isAuthenticated
    )
      return;

    setModalType(UserModals.LOGIN);
    setEmail(searchParams.get('email') as string);
  }, [searchParams?.get('action')]);

  const logout = async () => {
    const { logout } = await import('@/lib/authentication/helpers');
    logout();
    await router.replace('/');
    window.location.reload();
  };

  const showModal = (modalType: UserModals) => {
    if (isMobile) {
      document.dispatchEvent(new CustomEvent('modalOpened'));
    }
    setModalType(modalType);
  };

  const showLoginModal = () => {
    showModal(UserModals.LOGIN);
  };

  const showSignupModal = () => {
    showModal(UserModals.SIGNUP);
  };

  const showResetModal = () => {
    showModal(UserModals.RESET);
  };

  const replaceUrlWithoutActionAndRedirect = () => {
    const allSearchParams = getAllParamsFromSearchParams(searchParams);

    // Remove redirectUrl and action query params but keep the rest
    const newSearchParams = {
      ...allSearchParams,
      action: undefined,
      redirectUrl: undefined,
      closable: undefined,
    };
    router.replace(stringifyUrl(pathname!, newSearchParams), undefined);
  };

  const closeModal = () => {
    setModalType(undefined);
    replaceUrlWithoutActionAndRedirect();
  };

  const handleLoginSuccess = () => {
    setModalType(undefined);
    if (searchParams?.get('redirectUrl')) {
      return router.replace(`${searchParams?.get('redirectUrl')}`);
    }
    if (searchParams?.get('action') === HeaderQueryActions.LOGIN) {
      return replaceUrlWithoutActionAndRedirect();
    }
    router.refresh();
  };

  return {
    isClosable:
      searchParams?.get('closable') === StringBool.False ? false : true,
    modalType,
    email,
    showLoginModal,
    showSignupModal,
    showResetModal,
    logout,
    handleLoginSuccess,
    closeModal,
  };
}
