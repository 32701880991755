import { useTranslations } from 'next-intl';
import { useEffect } from 'react';

import { useAuthData } from '@/hooks/useAuthData';
import { ScreenSize, useScreenSize } from '@/hooks/useScreenSize';
import { usePathname } from '@/i18n/navigation';

import UserModal from '../../UserModal';
import UserIcon from '../../icons/UserIcon';
import useUserModal from '../hooks/useUserModal';
import HorizontalNavDropdown, {
  HorizontalNavDropdownProps,
} from '../shared/HorizontalNavDropdown';
import SubMenuItem from '../shared/SubMenuItem';

type NuveenDesktopUserDropdownProps = Pick<
  HorizontalNavDropdownProps,
  'onKeyDown' | 'onMouseEnter' | 'isExpanded' | 'className'
> & { onSubMenuClick: () => void };

export default function NuveenDesktopUserDropdown({
  onSubMenuClick,
  ...dropdownProps
}: NuveenDesktopUserDropdownProps) {
  const { isAuthenticated, isLoading } = useAuthData();
  const t = useTranslations('nav');
  const screenSize = useScreenSize();

  const pathname = usePathname();
  const {
    isClosable,
    modalType,
    email,
    closeModal,
    logout,
    handleLoginSuccess,
    showLoginModal,
    showSignupModal,
    showResetModal,
  } = useUserModal();

  useEffect(() => {
    if (isLoading) return;

    if (!isAuthenticated && screenSize >= ScreenSize.SM) {
      showSignupModal();
    }
  }, [isLoading, screenSize]);

  const handleLogout = async () => {
    onSubMenuClick();
    logout();
  };

  const handleLogin = () => {
    onSubMenuClick();
    showLoginModal();
  };

  const handleSignup = () => {
    onSubMenuClick();
    showSignupModal();
  };

  return (
    <>
      <HorizontalNavDropdown
        dataTestId={isAuthenticated ? 'nav-supplier' : 'nav-account'}
        arialLabel={t('account')}
        icon={<UserIcon className="header-transparent:text-white" />}
        subMenuClassName="right-0"
        isHighlighted={pathname === '/account/account-settings'}
        {...dropdownProps}
      >
        {isLoading && <div>{t('loading')}...</div>}
        {!isLoading && isAuthenticated && (
          <>
            <SubMenuItem
              href={'/account/account-settings'}
              dataTestId="nav-account"
            >
              {t('account')}
            </SubMenuItem>
            <SubMenuItem onClick={handleLogout} dataTestId="nav-logout">
              {t('logout')}
            </SubMenuItem>
          </>
        )}

        {!isLoading && !isAuthenticated && (
          <>
            <SubMenuItem onClick={handleLogin} dataTestId="nav-login">
              {t('login')}
            </SubMenuItem>
            <SubMenuItem onClick={handleSignup} dataTestId="nav-signup">
              {t('signup')}
            </SubMenuItem>
          </>
        )}
        {modalType && (
          <UserModal
            modal={modalType}
            email={email}
            closable={isClosable}
            onLoginSuccess={handleLoginSuccess}
            onClose={closeModal}
            onSignUpClick={showSignupModal}
            onLoginClick={showLoginModal}
            onResetClick={showResetModal}
          />
        )}
      </HorizontalNavDropdown>
    </>
  );
}
