'use client';

import { useLocale } from 'next-intl';

import ChevronIcon from '@/components/icons/ChevronIcon';
import useToggle from '@/hooks/useToggle';
import { usePathname } from '@/i18n/navigation';
import { LOCALES, LOCALES_TO_HIDE, LOCALE_LABELS } from '@/utils/constants';
import { COOKIE_KEY_LOCALE, setCookieByKey } from '@/utils/cookies';
import { Locale } from '@/utils/type-utils';

import SubMenuItem from '../shared/SubMenuItem';

import clsx from 'clsx';

export default function MobileLanguageDropdown() {
  const locale = useLocale();
  const pathname = usePathname();
  const [isOpened, toggleIsOpened] = useToggle();

  const selectableLocales = LOCALES.filter((l) => !LOCALES_TO_HIDE.includes(l));

  const handleLocaleClick = (locale: Locale) => {
    setCookieByKey(COOKIE_KEY_LOCALE, locale);
  };

  return (
    <li
      className="box-content p-5 border-t border-grey-90 first:border-t-0"
      key={locale}
      role="menuitem"
    >
      <button
        className="flex items-center gap-2 font-bold"
        data-testid="nav-locale"
        type="button"
        onClick={() => toggleIsOpened()}
      >
        {locale.toUpperCase()}
        <ChevronIcon
          className={clsx(
            ' stroke-teal-5',
            'focus:rotate-180 transition-transform duration-200',
            isOpened && 'rotate-180'
          )}
          isBold={true}
        />
      </button>

      {isOpened && (
        <ul className="flex flex-col mb-2 gap-7 mt-7" role="menu">
          {selectableLocales.map((l) => (
            <SubMenuItem
              key={l}
              dataTestId={`nav-${l}`}
              href={pathname}
              onClick={() => handleLocaleClick(l)}
              isSelected={l === locale}
              locale={l}
            >
              {LOCALE_LABELS[l]}
            </SubMenuItem>
          ))}
        </ul>
      )}
    </li>
  );
}
