import { useLocale } from 'next-intl';

import { usePathname } from '@/i18n/navigation';
import { LOCALES, LOCALES_TO_HIDE, LOCALE_LABELS } from '@/utils/constants';
import { COOKIE_KEY_LOCALE, setCookieByKey } from '@/utils/cookies';
import { Locale } from '@/utils/type-utils';

import HorizontalNavDropdown, {
  HorizontalNavDropdownProps,
} from '../shared/HorizontalNavDropdown';
import SubMenuItem from '../shared/SubMenuItem';

type LanguageDropdownProps = Pick<
  HorizontalNavDropdownProps,
  'onKeyDown' | 'onMouseEnter' | 'isExpanded' | 'className'
> & { onSubMenuClick: () => void };

export default function LanguageDropdown({
  onSubMenuClick,
  ...dropdownProps
}: LanguageDropdownProps) {
  const locale = useLocale();
  const pathname = usePathname();

  const handleLocaleClick = (locale: Locale) => {
    setCookieByKey(COOKIE_KEY_LOCALE, locale);
    onSubMenuClick();
  };

  return (
    <HorizontalNavDropdown
      dataTestId="nav-locale"
      label={locale.toLocaleUpperCase()}
      btnClassName="px-2 py-[2px] border rounded-full border-teal-99 border-opacity-40 font-bold header-transparent:text-opacity-80 !h-fit"
      subMenuClassName="max-xl:right-0"
      {...dropdownProps}
    >
      {LOCALES.filter((l) => !LOCALES_TO_HIDE.includes(l)).map((l) => (
        <SubMenuItem
          key={l}
          dataTestId={`nav-${locale}`}
          href={pathname}
          onClick={() => handleLocaleClick(l)}
          isSelected={l === locale}
          locale={l}
        >
          {LOCALE_LABELS[l]}
        </SubMenuItem>
      ))}
    </HorizontalNavDropdown>
  );
}
